import { Icon } from "@iconify-icon/react";
import { useContext } from "react";
import { ConfigContext } from "../../contexts/ConfigContext";
import { countries } from "../../data/countries";
import { useTranslation } from "react-i18next";

function NonEligibleScreen({
  stepKey,
  travelReason,
  citizenshipCountry,
  currentStep,
  setCurrentStep,
}: {
  stepKey: string;
  travelReason: string;
  citizenshipCountry: string;
  currentStep: string;
  setCurrentStep: Function;
}) {
  const { config } = useContext(ConfigContext);
  const { t } = useTranslation();

  const ACTIONS = {
    INITIAL: "INITIAL",
    SUPPORT: "SUPPORT",
  };

  const handleContinueClick = (action: string) => {
    switch (action) {
      case ACTIONS.INITIAL:
        setCurrentStep(ACTIONS.INITIAL);
        break;
      default:
        window.location.href = config.supportUrl;
    }
  };

  return stepKey !== currentStep ? null : (
    <div className="non-eligible-screen text-lg">
      <div className="text-center text-gray-600 mb-3">
        <Icon className="text-5xl" icon="akar-icons:circle-x" />
      </div>
      <div
        className="text-xl mb-6 text-center"
        dangerouslySetInnerHTML={{
          __html: t("NOT_ELIGIBLE_CITIZENSHIP", {
            citizenship:
              countries[citizenshipCountry as keyof typeof countries],
          }),
        }}
      ></div>

      <div className="text-center mb-6">
        {t("APPLY_WITH_OTHER_CITIZENSHIP")}
      </div>

      <div className="text-center flex justify-center items-center gap-4">
        <button
          onClick={() => handleContinueClick(ACTIONS.INITIAL)}
          className="bg-jade-700 border-jade-700 text-white px-8 py-3 text-lg rounded font-bold border-2"
        >
          {t("TRY_WITH_OTHER_CITIZENSHIP")}
        </button>
        <button
          onClick={() => handleContinueClick(ACTIONS.SUPPORT)}
          className="text-green-600 px-8 py-3 text-lg rounded font-bold border-2 border-jade-700"
        >
          {t("CONTACT_US")}
        </button>
      </div>
    </div>
  );
}

export default NonEligibleScreen;
