import { useContext, useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { ConfigContext } from "../../contexts/ConfigContext";
import { isCountryETAEligible } from "../../utils/eta";
import ReasonOption from "../ReasonOption";
import { useTranslation } from "react-i18next";

function InitialScreen({
  stepKey,
  setTravelReason,
  setCitizenshipCountry,
  travelReason,
  citizenshipCountry,
  currentStep,
  setCurrentStep,
}: {
  stepKey: string;
  setTravelReason: Function;
  setCitizenshipCountry: Function;
  travelReason: string;
  citizenshipCountry: string;
  currentStep: string;
  setCurrentStep: Function;
}) {
  const [continueClicked, setContinueClicked] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const {
    config: { detectedCountry = "", fallbackUrl },
  } = useContext(ConfigContext);

  const { t } = useTranslation();

  const updateErrors = () => {
    let _errors: string[] = [];
    if (!travelReason) _errors = [..._errors, t("TRAVEL_REASON_ERROR")];
    if (!citizenshipCountry) _errors = [..._errors, t("CITIZENSHIP_ERROR")];

    setErrors(_errors);

    return _errors;
  };

  const handleContinueClick = () => {

    setContinueClicked(true);
    const _errors = updateErrors();

    if (_errors.length === 0) {
      if (["PR", "US"].includes(citizenshipCountry)) {
        console.log("USA");
        setCurrentStep("USA-ELIGIBLE");
        return;
      }

      if (["MX"].includes(citizenshipCountry)) {
        console.log("Mexico");
        setCurrentStep("MX-ELIGIBLE");
        return;
      }

      if (isCountryETAEligible(citizenshipCountry)) {
        console.log("ELIGIBLE");
        setCurrentStep("ELIGIBLE");
      } else {
        if(fallbackUrl) {
          window.location.href=fallbackUrl
        } else {
          setCurrentStep("NON-ELIGIBLE");
        }

      }
    }
  };

  useEffect(() => {
    if (continueClicked) updateErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelReason, citizenshipCountry]);

  useEffect(() => {
    console.log(!citizenshipCountry && detectedCountry, {
      citizenshipCountry,
      detectedCountry,
    });
    if (!citizenshipCountry && detectedCountry) {
      setCitizenshipCountry(detectedCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectedCountry]);

  return stepKey !== currentStep ? null : (
    <div className="initial-screen md:min-w-[600px]">
      <div className="text-xl mb-4"></div>
      <div className="text-xl mb-4">{t("WHAT_IS_YOUR_CITIZENSHIP")}</div>
      <div className="mb-6">
        <ReactFlagsSelect
          className="country-select"
          placeholder={t("SELECT_COUNTRY")}
          selected={citizenshipCountry}
          onSelect={(code) => setCitizenshipCountry(code)}
          searchable
        />
      </div>

      <div className="text-xl mb-4">{t("SELECT_REASONS")}</div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
        <ReasonOption
          name={t("TOURISM")}
          id="tourism"
          icon="akar-icons:map"
          selected={travelReason}
          onClick={setTravelReason}
        ></ReasonOption>

        <ReasonOption
          name={t("BUSINESS")}
          id="business"
          icon="ic:round-business-center"
          selected={travelReason}
          onClick={setTravelReason}
        ></ReasonOption>

        <ReasonOption
          name={t("TRANSIT")}
          id="transit"
          icon="fa-solid:plane"
          selected={travelReason}
          onClick={setTravelReason}
        ></ReasonOption>

        <ReasonOption
          name={t("STUDY")}
          id="study"
          icon="fa6-solid:graduation-cap"
          selected={travelReason}
          onClick={setTravelReason}
        ></ReasonOption>
      </div>

      {errors.length > 0 && (
        <div className="text-red-600 rounded mb-6">
          <div className="font-bold mb-2">{t("FIX_ERROR_TO_CONTENU")}</div>
          {errors.map((error, i) => (
            <div key={`error-${i}`}>- {error}</div>
          ))}
        </div>
      )}

      <div className="text-center">
        <button
          onClick={handleContinueClick}
          className={`${citizenshipCountry && travelReason
            ? "bg-jade-700 border-jade-700 text-white"
            : "bg-gray-300 text-gray-600"
            } px-8 py-3 text-lg rounded font-bold`}
        >
          {t("CONTINUE")}
        </button>
      </div>
    </div>
  );
}

export default InitialScreen;
