import { Icon } from "@iconify-icon/react";
import { useContext } from "react";
import { ConfigContext } from "../contexts/ConfigContext";

function ReasonOption({
  icon,
  name,
  id,
  selected,
  onClick,
}: {
  icon: string;
  name: string;
  id: string;
  selected: string;
  onClick: Function;
}) {
  const {
    config: { primaryColor = null },
  } = useContext(ConfigContext);
  const isSelected = selected === id;

  return (
    <div
      ref={(element) => {
        if (element && primaryColor)
          element.setAttribute(
            "style",
            `background: ${
              isSelected ? "transparent" : primaryColor
            } !important; border-color:  ${primaryColor} !important; color: ${
              isSelected ? primaryColor : "white"
            } !important`
          );
      }}
      className={`${
        isSelected
          ? "bg-transparent text-slate-800 font-bold"
          : "bg-slate-800 text-white"
      } reason-option-${id} border-2 border-slate-800 cursor-pointer flex items-center gap-2 rounded p-4`}
      onClick={() => onClick(id)}
    >
      <Icon icon={icon} />
      <div>{name}</div>
    </div>
  );
}

export default ReasonOption;
